import moment from "moment"

export function getSearchDates(start, end) {
  const from = moment.utc(start)
  const to = moment.utc(end)

  let currentDate = moment(from) //.add(1, "d");

  const dates = []

  while (currentDate.isBetween(from, to, null, "[]")) {
    const dateString = currentDate.format("DD-MM-YYYY").toString()

    dates.push(dateString)

    currentDate = moment(currentDate).add(1, "d")
  }

  return dates
}
