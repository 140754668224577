import React, { useEffect, useState } from "react"
import { Configure, InstantSearch } from "react-instantsearch-core"
import {
  connectHits,
  connectRefinementList,
  // SearchBox,
} from "react-instantsearch-dom"
import Hit from "../components/search/Hit"
import Seo from "../components/seo"
// import { Link } from "gatsby"
import qs from "qs"
import Layout from "../components/layout"
// import BottomBar from "../components/BottomBar"
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
} from "react-instantsearch-dom-maps"
import { getCustomSearchClient, getSearchClient } from "../utils/algolia"
import { Fragment } from "react"
import { getSearchDates } from "../utils/propertyUtil"
import { addDays, format } from "date-fns"
import { navigate } from "gatsby"

export default function Page({ location, data, history }) {
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [adults, setAdults] = useState()
  const [children, setChildren] = useState()
  const [doSearch, setDoSearch] = useState(true)
  const [filters, setFilters] = useState()

  const getFilter = (start, end, guests) => {
    const dates = getSearchDates(start, end)

    const availabilityString = dates
      .map(d => ` AND availability:${d}`)
      .join("")
      .replace("AND ", "")

    const newFilter = `(${availabilityString}) AND guests >= ${guests}`
    return newFilter
  }

  useEffect(() => {
    const params = qs.parse(
      typeof window !== "undefined"
        ? window.location.search.substring(1)
        : `start=2021-08-01&end=2021-08-07&adults=4&children=2`
    )

    let { start, end, adults, children } = params

    if (!start) start = format(addDays(new Date(), 14), "yyyy-MM-dd")
    if (!end) end = format(addDays(new Date(), 21), "yyyy-MM-dd")

    if (!adults) adults = 4
    else adults = parseInt(adults)

    if (!children) children = 2
    else children = parseInt(children)

    setStart(start)
    setEnd(end)
    setAdults(adults)
    setChildren(children)

    const newFilter = getFilter(start, end, adults + children)

    setFilters(newFilter)

    // navigate("/villas?" + qs.stringify({ start, end, adults, children }))

    // setDoSearch(false)
  }, [])

  // const searchClient = getSearchClient(true)
  const searchClient = getCustomSearchClient(
    doSearch,
    start,
    end,
    adults,
    children
  )

  const handleSearch = () => {
    const newFilter = getFilter(start, end, adults + children)
    setFilters(newFilter)
  }
  return (
    <Layout>
      <div className="font-landing-sans">
        <Seo title="Search" />
        {filters && (
          <InstantSearch searchClient={searchClient} indexName="properties">
            <Configure filters={filters} />
            <div className="bg-gray-100 p-4 md:p-12 ">
              <div className="grid grid-cols-2 gap-2 md:gap-0 md:flex md:flex-row md:space-x-4 md:justify-center md:items-end font-property">
                {/* <SearchBox /> */}

                <Input
                  placeholder="start"
                  label="Check in date"
                  name="check-in"
                  value={start}
                  onChange={event => setStart(event.target.value)}
                  type="date"
                  className="px-4 py-2 h-11 rounded outline-none focus:outline-none w-full"
                />
                <Input
                  placeholder="end"
                  label="Check out date"
                  name="check-out"
                  value={end}
                  onChange={event => setEnd(event.target.value)}
                  type="date"
                  className="px-4 py-2 h-11 rounded outline-none focus:outline-none w-full"
                />
                <Select
                  placeholder="Adults"
                  label="Adults"
                  name="Adults"
                  value={adults}
                  onChange={event => setAdults(parseInt(event.target.value))}
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                  className="px-4 py-2 h-11 rounded outline-none focus:outline-none w-full"
                />
                <Select
                  placeholder="Children"
                  label="Children"
                  name="Children"
                  value={children}
                  onChange={event => setChildren(parseInt(event.target.value))}
                  options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                  className="px-4 py-2 h-11 rounded outline-none focus:outline-none w-full"
                />
                <button
                  className="text-white bg-black px-6 py-2 rounded-full outline-none focus:outline-none col-span-2"
                  onClick={handleSearch}
                >
                  search
                </button>
              </div>
            </div>
            <div className="grid grid-cols-12 mx-auto">
              <div className="col-span-12 md:col-span-3 lg:col-span-2 bg-gray-50 h-full p-4">
                <h1 className="font-semibold text-xl pb-2">Advanced Filter</h1>
                <CustomRefinementList
                  attribute="propertyFeatures.name"
                  className="tracking-widest text-h-2"
                  listName="Features"
                  showMore
                  showMoreLimit={100}
                  operator="and"
                />
              </div>
              <div className="col-span-12 md:col-span-9 lg:col-span-7 p-4 md:p-10">
                <CustomHits
                  start={start}
                  end={end}
                  adults={adults}
                  children={children}
                />
              </div>
              <div className="col-span-12 lg:col-span-3 bg-gray-50 h-[600px]">
                <Map />
              </div>
            </div>
            {/* <BottomBar nextStep="/booking/choose-villa" /> */}
          </InstantSearch>
        )}
      </div>
    </Layout>
  )
}

// const Hit = ({ hit }) => <div className="bg-gray-300 p-12">{hit.name}</div>

const Input = ({
  label,
  name,
  onChange,
  type,
  className,
  value,
  placeholder,
}) => {
  return (
    <div className="grid gap-1">
      <label htmlFor={name} className="font-bold">
        {label}
      </label>
      <input
        id={name}
        className={className}
        type={type}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  )
}

const Select = ({
  label,
  placeholder,
  className,
  options,
  onChange,
  value,
}) => {
  return (
    <div className="grid gap-1">
      <label className="font-bold  font-property">
        {label}
        <select
          placeholder={placeholder}
          className={className}
          onChange={onChange}
          value={value}
        >
          {options.map((opt, key) => {
            return (
              <option value={opt} key={key}>
                {opt}
              </option>
            )
          })}
        </select>
      </label>
    </div>
  )
}
const RefinementList = ({ items, refine, listName }) => (
  <div>
    <h2 className="font-semibold text-lg pb-6">{listName}</h2>
    <ul className="h-96 overflow-y-auto grid gap-1">
      {items.length === 0 && (
        <div className="flex justify-center">
          <svg
            className="animate-spin h-6 w-6 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
      {items.map(item => (
        <li className="text-base font-light" key={item.label}>
          <a
            href="#/"
            className={
              item.isRefined
                ? "font-bold flex justify-between items-center"
                : " flex items-center"
            }
            onClick={event => {
              event.preventDefault()
              refine(item.value)
            }}
          >
            <span>
              {item.label} ({item.count})
            </span>
            {item.isRefined && (
              <span className="h-6 w-6 flex items-center justify-center bg-black text-white text-xs font-bold rounded-full">
                X
              </span>
            )}
          </a>
        </li>
      ))}
    </ul>
  </div>
)

const CustomRefinementList = connectRefinementList(RefinementList)

const Hits = ({ hits, ...params }) => {
  return (
    <>
      {hits.length > 0 ? (
        <Fragment />
      ) : (
        // <h3 className="text-xl font-light">{hits.length} results founds</h3>
        <div className="flex justify-center">
          <svg
            className="animate-spin h-12 w-12 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
      <ol className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-10">
        {hits.map(hit => (
          <li key={hit.objectID}>
            {hit ? <Hit hit={hit} {...params} /> : <div>Loading</div>}
          </li>
        ))}
      </ol>
    </>
  )
}

const CustomHits = connectHits(Hits)

const Map = () => {
  return (
    <GoogleMapsLoader apiKey="AIzaSyCvYSJlYbYO7JNCWoCk7aEkI2pMBkWMPsw">
      {google => (
        <GeoSearch
          google={google}
          // mapTypeId={google.maps.MapTypeId.SATELLITE}
          zoom={11}
          enableRefine={true}
          maxZoom={12}
          // enableRefineOnMapMove={false}
        >
          {({ hits }) => {
            return (
              <>
                {hits.map((hit, i) => (
                  <div key={`map-hit-${i}`}>
                    <Marker key={hit.objectID} hit={hit} />
                  </div>
                ))}
              </>
            )
          }}
        </GeoSearch>
      )}
    </GoogleMapsLoader>
  )
}
